import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { ROUTES } from '@/constants';
import { useAppSelector } from '@/hooks';
import { getAdminRole, getUserLoaded } from '@/store/selectors';
import { EUserRole } from '@/types';

interface ProtectedRouteProps {
  allowedRoles: EUserRole[];
  children: React.ReactElement;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  children,
  allowedRoles,
}) => {
  const isUserLoaded = useAppSelector(getUserLoaded);
  const userRole = useSelector(getAdminRole);

  if (!isUserLoaded) {
    return null;
  }

  const hasPermission = allowedRoles.includes(userRole as EUserRole);

  if (!hasPermission) {
    return <Navigate to={ROUTES.DASHBOARD.REPORTS} />;
  }

  return children;
};
