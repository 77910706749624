import { Box, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

interface StyledDataGridProps {
  filterField?: string;
}

export const TableNavBar = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

export const NoResultsStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

export const StyledDataGrid = styled(DataGrid)<StyledDataGridProps>`
  .MuiDataGrid-filterIcon {
    display: none;
  }

  ${({ filterField }) =>
    filterField &&
    `.MuiDataGrid-columnHeader[data-field="${filterField}"] {
      .MuiDataGrid-menuIcon {
        display: flex;
        visibility: unset;
        margin-right: 0;

        button {
          color: rgba(0, 0, 0, 0.7);
          position: absolute;
          right: 0;
        }
      }
    }`}
`;
