import {
  AccountBox,
  ContactMail,
  People,
  Report,
  Settings,
} from '@mui/icons-material';
import { ReactNode } from 'react';

import { ROUTES } from '@/constants';
import { EUserRole } from '@/types';

interface ISideMenuItem {
  text: string;
  url: string;
  icon: ReactNode;
  badge?: EBadgeType;
  protectedFrom?: Array<EUserRole>;
}

export enum EBadgeType {
  REPORT = 'report',
}

export const menuItemsLilypad: Array<ISideMenuItem> = [
  {
    text: 'Reporting',
    url: ROUTES.DASHBOARD.REPORTS,
    icon: <Report />,
    badge: EBadgeType.REPORT,
  },
  {
    text: 'Users',
    url: ROUTES.DASHBOARD.USERS,
    icon: <People />,
  },
  {
    text: 'Applications for registration',
    url: ROUTES.DASHBOARD.REGISTRATION_APPLICATIONS,
    icon: <ContactMail />,
  },
  {
    text: 'Lilypad Settings',
    url: ROUTES.DASHBOARD.LILYPAD_SETTINGS,
    icon: <Settings />,
    protectedFrom: [EUserRole.MODERATOR],
  },
];

export const menuItems: Array<ISideMenuItem> = [
  {
    text: 'Admins',
    url: ROUTES.DASHBOARD.ADMINS,
    icon: <AccountBox />,
  },
  {
    text: 'Settings',
    url: ROUTES.DASHBOARD.SETTINGS,
    icon: <Settings />,
  },
];
