import { createSlice } from '@reduxjs/toolkit';

import { ERegistrationApplicationStatus } from '@/types';
import { RegistrationApplicationsSlice } from '@/types/slices';

import {
  getMoreRegistrationApplications,
  getRegistrationApplications,
  sendRegistrationAvailableNotification,
} from '../thunks';

const initialState: RegistrationApplicationsSlice = {
  registrationApplications: [],
  newApplicationsCount: 0,
  totalCount: 0,
  isContentLoading: false,
  isMailSending: false,
};

export const registrationApplicationsSlice = createSlice({
  name: 'registrationApplicationsSlice',
  initialState,
  reducers: {
    clearRegistrationApplications: (state) => {
      state.registrationApplications = [];
      state.totalCount = 0;
      state.newApplicationsCount = 0;
      state.isContentLoading = false;
      state.isMailSending = false;
    },
    setIsRegistrationApplicationsLoading: (state, { payload }) => {
      state.isContentLoading = payload;
    },
    setIsMailSending: (state, { payload }) => {
      state.isMailSending = payload;
    },
    setNewApplicationsCount: (state, { payload }) => {
      state.newApplicationsCount = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRegistrationApplications.fulfilled, (state, { payload }) => {
        if (payload) {
          state.totalCount = payload.meta.totalCount;
          state.newApplicationsCount = payload.newCount;
          state.registrationApplications = payload.data;
        }
      })
      .addCase(
        getMoreRegistrationApplications.fulfilled,
        (state, { payload }) => {
          if (payload) {
            const myRegistrationApplications = payload.data;

            state.totalCount = payload.meta.totalCount;
            state.registrationApplications = state.registrationApplications
              ? [
                  ...state.registrationApplications,
                  ...myRegistrationApplications,
                ]
              : myRegistrationApplications;
          }
        }
      )
      .addCase(
        sendRegistrationAvailableNotification.fulfilled,
        (state, { payload }) => {
          if (!payload) {
            state.registrationApplications.forEach((item) => {
              if (item.status !== ERegistrationApplicationStatus.SENT) {
                item.status = ERegistrationApplicationStatus.SENT;
                item.sentAt = new Date().toISOString();
              }
            });
            return;
          }

          payload.forEach((id) => {
            const itemIndex = state.registrationApplications.findIndex(
              (item) => item.id === id
            );

            if (itemIndex !== -1) {
              state.registrationApplications[itemIndex] = {
                ...state.registrationApplications[itemIndex],
                status: ERegistrationApplicationStatus.SENT,
                sentAt: new Date().toISOString(),
              };
            }
          });
        }
      );
  },
});
